
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import moment from "moment";
import { FolderDataSource } from "@/data/Tree/FolderDataSource";
@Component
export default class Home extends Vue {
  moment: any = moment;
  dataSource: ListDataSource = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
    className: "ClientWebOrder",
  });

  folderDataSource: FolderDataSource = new FolderDataSource({
    className: "ClientEventFolder",
    rootFolder: "mainFolder",
  });

  customMetaData: any = {
    groups: [
      {
        name: "general",
        caption: "Общие",
        priority: 1,
      },
      {
        name: "linkedcomponents",
        caption: "Связанные объекты",
        priority: 10,
      },
      {
        name: "rating",
        caption: "Рейтинг и отзывы",
        priority: 20,
      },
      {
        name: "visible",
        caption: "Статус и видимость",
        priority: 30,
      },
      {
        name: "images",
        caption: "Изображения",
        priority: 40,
      },
      {
        name: "seo",
        caption: "SEO",
        priority: 60,
      },
      {
        name: "autolinks",
        caption: "Перелинковка",
        priority: 70,
      },
      {
        name: "settings",
        caption: "Настройки",
        priority: 80,
      },
    ],
    properties: [
      {
        name: "userName",
        type: "string",
        description: "ФИО пользователя",
        caption: "ФИО",
        editor: "string",
        priority: 100,
        group: "general",
        visibleInTable: true,
        validations: [
          {
            validator: "max",
            value: 200,
            errorMessage: "Поле не может содержать больше чем 200 символов",
          },
          {
            validator: "required",
            value: true,
            errorMessage: "Поле не может быть пустым",
          },
        ],
        config: {},
      },
      {
        name: "phone",
        type: "string",
        description: "Телефон",
        caption: "Телефон",
        editor: "string",
        priority: 100,
        group: "general",
        visibleInTable: false,
        validations: [
          {
            validator: "max",
            value: 200,
            errorMessage: "Поле не может содержать больше чем 200 символов",
          },
          {
            validator: "required",
            value: true,
            errorMessage: "Поле не может быть пустым",
          },
        ],
        config: {},
      },
      {
        name: "email",
        type: "string",
        description: "Email",
        caption: "Email",
        editor: "string",
        priority: 100,
        group: "general",
        visibleInTable: false,
        validations: [
          {
            validator: "max",
            value: 200,
            errorMessage: "Поле не может содержать больше чем 200 символов",
          },
          {
            validator: "required",
            value: true,
            errorMessage: "Поле не может быть пустым",
          },
        ],
        config: {},
      },
      {
        name: "url",
        type: "string",
        description: "URL",
        caption: "URL",
        editor: "string",
        priority: 100,
        group: "general",
        visibleInTable: false,
        validations: [
          {
            validator: "max",
            value: 300,
            errorMessage: "Поле не может содержать больше чем 300 символов",
          },
        ],
        config: {},
      },
      {
        name: "title",
        type: "string",
        description: "Заголовок",
        caption: "Заголовок",
        editor: "string",
        priority: 100,
        group: "general",
        visibleInTable: true,
        validations: [
          {
            validator: "max",
            value: 200,
            errorMessage: "Поле не может содержать больше чем 200 символов",
          },
        ],
        config: {},
      },
      {
        name: "companyName",
        type: "string",
        description: "Название компании",
        caption: "Название компании",
        editor: "string",
        priority: 100,
        group: "general",
        visibleInTable: false,
        validations: [
          {
            validator: "max",
            value: 200,
            errorMessage: "Поле не может содержать больше чем 200 символов",
          },
        ],
        config: {},
      },
      {
        name: "date",
        type: "datetime",
        description: "Дата события",
        caption: "Дата события",
        editor: "readonly",
        priority: 2,
        group: "general",
        visibleInTable: true,
        validations: [],
        config: {},
      },
      {
        name: "comment",
        type: "string",
        description: "Комментарий",
        caption: "Комментарий",
        editor: "memo",
        priority: 2,
        group: "general",
        visibleInTable: false,
        validations: [],
        config: {},
      },
      {
        name: "attachedFiles",
        type: "file",
        description: null,
        caption: "Связанные файлы",
        editor: "fileuploader",
        priority: 100,
        group: "linkedcomponents",
        visibleInTable: false,
        validations: [],
        items: [],
      },
      {
        name: "folderId",
        type: "objectlist",
        description: "Категория",
        caption: "Категория",
        editor: "folder",
        priority: 100,
        group: "linkedcomponents",
        visibleInTable: false,
        validations: [],
        config: {
          className: "ClientEventFolder",
        },
      },
      {
        name: "id",
        type: "string",
        description: "Уникальный идентификатор компонента.",
        caption: "ID",
        editor: "readonly",
        priority: 0,
        group: "general",
        visibleInTable: true,
        validations: [],
        config: {},
      },
      {
        name: "createDate",
        type: "date",
        description: "Дата создания компонента",
        caption: "Дата создания",
        editor: "readonly",
        priority: 1,
        group: "general",
        visibleInTable: true,
        validations: [],
        config: {},
      },
      {
        name: "lastUpdate",
        type: "date",
        description: "Дата последнего обновления",
        caption: "Дата последнего обновления",
        editor: "readonly",
        priority: 200,
        group: "general",
        visibleInTable: false,
        validations: [],
        config: {},
      },
      {
        name: "priority",
        type: "number",
        description:
          "Значение данного поля используется при сортировке группы однородных компонентов, если явно указано, что сортировка осуществляется по этому полю.",
        caption: "Приоритет",
        editor: "string",
        priority: 100,
        group: "general",
        visibleInTable: false,
        validations: [],
        config: {},
      },
      {
        name: "instanceStateId",
        type: "enum",
        description: null,
        caption: "Статус",
        editor: "enum",
        priority: 100,
        group: "visible",
        visibleInTable: true,
        validations: [],
        items: [
          {
            id: 1,
            caption: "Создан",
          },
          {
            id: 12,
            caption: "В работе",
          },
          {
            id: 11,
            caption: "Выполнен",
          },
        ],
      },
    ],
  };

  customStateColor(instanceStateId) {
    switch (instanceStateId) {
      case 12:
        return "primary";
      case 1:
        return "warning";
      case 11:
        return "success";
      default:
        return "default";
    }
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Онлайн заявки",
        disabled: true,
      },
    ];
  }
}
